import { IconBasket, IconBurgerMenu, IconSearch } from '@components/UI/Icons/VD/General'
import { CloseIcon } from '@components/UI/Icons/close'
import { CART } from '@constants/routes'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus, setOpenDrawerSearch } from '@features/ui/action'
import { hamburgerMenuOpenSelector, openDrawerSearchSelector } from '@features/ui/selector'
import useBreakpoints from '@hooks/useBreakpoints'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { FC, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContentIconButton, NavIcon, ContentIcon } from './NavigationIcon.style'

const NavigationIcon: FC = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isDesktop } = useBreakpoints()
  const isSearchOpenDrawer = useSelector(openDrawerSearchSelector)
  const isHamburgerDrawerOpen = useSelector(hamburgerMenuOpenSelector)

  const headerCTAIcons: {
    buttonAriaLabel: string
    children: ReactNode
    containerProps?: Record<string, string>
    icon?: FC
    isHidden?: boolean
    dataName?: string
    buttonOnClick(): void
  }[] = [
    {
      buttonAriaLabel: t('Header.Actions.Search'),
      buttonOnClick: () => {
        setTimeout(() => {
          dispatch(setOpenDrawerSearch(!isSearchOpenDrawer))
        }, 0)
      },
      children: !isSearchOpenDrawer ? <IconSearch /> : <CloseIcon />,
      containerProps: {
        'data-element-id': 'MainNav_Search',
        'data-name': 'search',
        'data-testid': 'search-desktop-largetablet-element',
      },
      isHidden: isHamburgerDrawerOpen,
    },
    {
      buttonAriaLabel: t('Header.Actions.Cart'),
      buttonOnClick: () => {
        router.push(`/${CART}`)
        dispatch(setCloseDrawerMegaMenu())
      },
      children: <IconBasket />,
      containerProps: { 'data-element-id': 'X_X_MainNav_Bag' },
      isHidden: isDesktop,
      dataName: 'miniBasket',
    },
    {
      buttonAriaLabel: t('Header.Actions.Hamburger'),
      buttonOnClick: () => {
        setTimeout(() => {
          dispatch(setHamburgerMenuOpenStatus(!isHamburgerDrawerOpen))
        }, 0)
      },
      children: !isHamburgerDrawerOpen ? <IconBurgerMenu /> : <CloseIcon />,
      containerProps: { 'data-element-id': 'X_X_MainNav_Menu' },
      isHidden: isDesktop || isSearchOpenDrawer,
    },
  ]

  return (
    <NavIcon>
      {headerCTAIcons.map(({ buttonAriaLabel, children, containerProps, isHidden, dataName, buttonOnClick }, i) => {
        if (isHidden) return null
        return (
          <ContentIconButton key={i} {...containerProps}>
            <ContentIcon aria-label={buttonAriaLabel} onClick={buttonOnClick} data-name={dataName}>
              {children}
            </ContentIcon>
          </ContentIconButton>
        )
      })}
    </NavIcon>
  )
}

export default NavigationIcon
