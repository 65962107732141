import {
  addWunderkindConversionScript,
  addWunderkindSmartTag,
  isWunderkindEnabledForLocale,
  WUNDERKIND_DEFAULT_SCRIPT_ID,
  WUNDERKIND_DEFAULT_SMART_TAG_ID,
  WunderkindConversionScriptProps,
} from '@components/Wunderkind/Wunderkind'
import { useEffect } from 'react'
import { useStoreIdentity } from '@hooks/useStoreIdentity'
import { WunderkindLocales } from '@components/Wunderkind/types'
import { useSelector } from 'react-redux'
import { userEmailSelector } from '@features/user/selector'
import { cartSelector } from '@features/order/selector'

export const useWunderkindSmartTag = () => {
  const { langCode } = useStoreIdentity()
  const userEmail = useSelector(userEmailSelector)
  const cart = useSelector(cartSelector)

  useEffect(() => {
    const shouldAddTag = !(
      !document ||
      !isWunderkindEnabledForLocale(langCode) ||
      document.getElementById(WUNDERKIND_DEFAULT_SMART_TAG_ID)
    )
    if (shouldAddTag) {
      addWunderkindSmartTag({ document, langCode: langCode as WunderkindLocales })
    }
  }, [langCode])

  useEffect(() => {
    if (isWunderkindEnabledForLocale(langCode) && window?.wunderkind) {
      window.wunderkind.user = {
        email: !!userEmail ? userEmail : '',
      }
    }
  }, [userEmail])

  useEffect(() => {
    if (isWunderkindEnabledForLocale(langCode) && window?.wunderkind) {
      window.wunderkind.cart = {
        value: !!cart ? +cart.grandTotal : 0,
        quantity: !!cart ? +cart.orderItem?.length : 0,
      }
    }
  }, [cart])
}

export const useWunderkindConversionScript = ({
  orderDetails,
  orderItem,
  langCode,
}: WunderkindConversionScriptProps) => {
  useEffect(() => {
    const shouldLoadScript = !(
      !orderDetails ||
      !orderItem ||
      !isWunderkindEnabledForLocale(langCode) ||
      document?.getElementById(WUNDERKIND_DEFAULT_SCRIPT_ID)
    )

    if (shouldLoadScript) {
      addWunderkindConversionScript({ orderDetails, langCode, orderItem })
    }
  }, [orderDetails, orderItem, langCode])
}
