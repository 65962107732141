import { shallowEqual, useSelector } from 'react-redux'

import { DEFAULT_LANG_ID } from '@constants/common'
import { Maybe } from '@typesApp/common'

import config from '@configs/index'
import { countryUtil } from '@utils/countryUtil'
import { RootReducerState } from '@redux/reducers'
export interface StoreIdentity {
  basePath: string
  country: string
  isRXEnabled: boolean
  langCode: string
  langId: string
  storeId: string
}

export interface IAlternateURL {
  key: string
  value: string
}

const getWithFallback = (value: Maybe<string>, fallback: string) => {
  if (!value || value === 'undefined' || value.trim() === '') return fallback
  return value
}

export const langCodeExceptionMap: Record<string, string> = {
  en_GB: 'en_UK',
  pt_PT: 'en_PT',
  nl_NL: 'en_NL',
}

export const useStoreIdentity = (): StoreIdentity => {
  return useSelector((state: RootReducerState) => {
    const locale = state.site.currentSite?.locale || null
    const storeId = state.site.currentSite?.storeID ?? ''
    const country = state.site.currentSite?.country || config.defaultCountry
    const langCode = getWithFallback(locale, config.defaultLocale)
    const langId = `${
      state?.site?.currentSite?.langId || state?.site?.currentSite?.defaultLanguageID || DEFAULT_LANG_ID
    }`
    const { currentLangCode } = countryUtil(country, langCode)
    const finalLangCode = currentLangCode.trim() !== '' ? currentLangCode : config.defaultLocale

    return {
      isRXEnabled: state.site.currentSite?.xStoreCfg?.addPrescriptionLenses ?? false,
      langCode: finalLangCode,
      country,
      basePath: '',
      langId,
      storeId,
    }
  }, shallowEqual)
}
