import { ButtonBase, styled } from '@mui/material'
import Link from 'next/link'

export const StyledMainNav = styled('div', {
  name: 'MainNav',
})(() => ({
  display: 'flex',
  height: 'inherit',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}))

export const UtilityNavWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(20),
}))

const NavButtonBase = ({ isActive, theme, isBasket }) => {
  const commonStyles = {
    color: theme.palette.common.black,
    boxShadow: `inset 0 -50px 0 -1px ${theme.palette.common.white}`,
  }

  return {
    ...(isBasket
      ? { ...theme.typography.subtitle2, padding: theme.spacing(0, 3.5) }
      : { ...theme.typography.body2, padding: theme.spacing(0, 16) }),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5),
    color: theme.palette.common.white,
    boxShadow: 'rgb(255, 255, 255) 0px 0px inset',
    transition: 'box-shadow 200ms ease 0ms',
    lineHeight: '40px',

    '&:hover': {
      ...commonStyles,
    },

    ...(isActive && commonStyles),
  }
}

export const SlideHoverBtn = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'isActive' && prop !== 'isBasket',
})<{ isActive?: boolean; isBasket?: boolean }>(({ theme, isActive, isBasket }) =>
  NavButtonBase({ isActive, theme, isBasket })
)

export const SlideHoverCta = styled(Link, {
  shouldForwardProp: prop => prop !== 'isActive' && prop !== 'isBasket',
})<{ isActive?: boolean; isBasket?: boolean }>(({ theme, isActive, isBasket }) =>
  NavButtonBase({ isActive, theme, isBasket })
)
