import { useEffect, useState } from 'react'
import Log from '../../services/Log'
import { executeOnce } from '../../utils/common'

import { sendAnalyticEventRaw } from '@foundation/analytics/tealium/lib'
import { useSite } from '../hooks/useSite'
import { BRAND, GENESYS_DEFAULT_TIME_ZONES, GENESYS_ENVIRONMENT, GENESYS_KEY, GENESYS_URL } from '../constants/genesys'

declare global {
  interface Window {
    Genesys: any
    sendData: () => void
  }
}
const GENESYS_LOADED_EVENT = 'genesysLoaded'

export const getTransformLocale = locale => {
  return locale.replace('_', '-').toLowerCase()
}

export const useGenesys = () => {
  const { mySite } = useSite()
  const [isLoading, setIsLoading] = useState(typeof window !== 'undefined' && !window?.Genesys)

  const hideDefaultToggle = () => {
    const chatWidget = document.getElementsByClassName('genesys-mxg-frame')?.[0] as HTMLElement
    if (chatWidget) {
      chatWidget.style.display = 'none'
    }
  }

  const loadScript = () => {
    const language = getTransformLocale(mySite?.locale)
    const country = mySite?.country.toUpperCase()
    const brandName = mySite?.xStoreCfg.brand
    const brand = BRAND[brandName]
    // TODO: get timezone from site xStoreConfig after its available
    const timezone = GENESYS_DEFAULT_TIME_ZONES[brand]
    const environment = mySite?.xStoreCfg['B2C_VD']?.environmentId ?? GENESYS_ENVIRONMENT[brand]
    const deploymentId = mySite?.xStoreCfg['B2C_VD']?.deploymentId ?? GENESYS_KEY[brand]

    return executeOnce(() => {
      window.Genesys =
        window.Genesys ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          ;(window.Genesys.q = window.Genesys.q || []).push(arguments)
        }
      window.Genesys.t = 1 * new Date().getTime()
      window.Genesys.c = {
        environment: environment,
        deploymentId: deploymentId,
      }

      let script = document.createElement('script')
      script.async = true
      script.src = GENESYS_URL[brand]
      script.charset = 'utf-8'
      document.head.appendChild(script)

      window.sendData = function () {
        window.Genesys('command', 'Database.set', {
          messaging: {
            customAttributes: {
              webmsg_brandcode: brand,
              webmsg_country: country,
              webmsg_language: language,
              webmsg_timezone: timezone,
            },
          },
        })
      }

      window.Genesys('subscribe', 'Messenger.opened', function () {
        window.sendData()
        const tealiumPayload = {
          id: 'Click',
          data_element_id: 'ChatBot_Open',
          data_description: 'Messenger.opened',
          data_analytics_available_call: '1',
        }
        sendAnalyticEventRaw(tealiumPayload)
      })
    }, 'genesys-chat')()
  }

  useEffect(() => {
    if (mySite) {
      try {
        loadScript()
      } catch (e: any) {
        Log.error('[Genesys Chat] LOAD SCRIPT ERROR ', e.message)
      }
    }
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const messengerDiv = document.getElementById('genesys-messenger')
          if (messengerDiv) {
            messengerDiv.setAttribute('data-element-id', 'ChatBot_Open')
            messengerDiv.setAttribute('data-analytics_available_call', '1')
            observer.disconnect()
            break
          }
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [mySite])

  useEffect(() => {
    if (window?.Genesys) {
      setIsLoading(false)
      hideDefaultToggle()
    } else {
      try {
        window.setTimeout(() => loadScript(), Number(process?.env?.REACT_APP_GENESYS_LOAD_DELAY || '1000'))
        const callback = () => {
          window.Genesys('subscribe', 'Messenger.ready', () => {
            setIsLoading(false)
            hideDefaultToggle()
          })
          window.removeEventListener(GENESYS_LOADED_EVENT, callback)
        }
        window.addEventListener(GENESYS_LOADED_EVENT, callback)
      } catch (e: any) {
        Log.error('[GENESYS] LOAD SCRIPT ERROR: ' + e.message, window.location.href)
      }
    }
  }, [])

  const closeGenesys = () => {
    window.Genesys('command', 'Messenger.close')
  }

  const toggleGenesys = () => {
    window.Genesys(
      'command',
      'Messenger.open',
      {},
      () => {}, // if resolved
      () => window.Genesys('command', 'Messenger.close') // if rejected
    )
  }

  return {
    isLoading,
    closeGenesys,
    toggleGenesys,
  }
}
