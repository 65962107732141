import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import config from '../../configs'
import { wordLiftKeySelector } from '../../redux/selectors/site'
import Log from '../../services/Log'
import { executeOnce } from '../../utils/common'
import { SiteInfo } from '@redux/reducers'

export const useWordLift = (site: SiteInfo) => {
  const isWordLiftEnabledConf = useSelector(wordLiftKeySelector)
  const isWordLiftEnabled = config.enableMonetate && isWordLiftEnabledConf

  const loadScript = () => {
    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const wordLiftTag = document.createElement('script')
      const src = 'https://cloud.wordlift.io/app/bootstrap.js'
      wordLiftTag.src = src
      wordLiftTag.async = true

      headFragment.appendChild(wordLiftTag)
      document.head.appendChild(headFragment)
    }, 'wordlift')()
  }

  useEffect(() => {
    if (isWordLiftEnabled && site) {
      try {
        loadScript()
      } catch (e: any) {
        Log.error(window.location.href, '[WORDLIFT] LOAD SCRIPT ERROR ', e.message)
      }
    }
  }, [isWordLiftEnabled, site])
}
