export const BRAND = { LensBest: 'LB', VisionDirect: 'VD', Clearly: 'CLE' }
export const GENESYS_ENVIRONMENT = {
  VD: 'prod-euw1',
  LB: 'prod-euw1',
  CLE: 'prod',
}
export const GENESYS_URL = {
  VD: 'https://apps.mypurecloud.ie/genesys-bootstrap/genesys.min.js',
  LB: 'https://apps.mypurecloud.ie/genesys-bootstrap/genesys.min.js',
  CLE: 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js',
}
export const GENESYS_KEY = {
  VD: '',
  LB: '',
  CLE: '58525254-a5f2-4743-b279-1659c8c54ba7',
}
export const GENESYS_DEFAULT_TIME_ZONES = {
  VD: 'Europe/London',
  LB: 'Europe/London',
  CLE: 'America/Vancouver',
}
