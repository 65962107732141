import React from 'react'
import { useTranslation } from 'next-i18next'
import { CART } from '@constants/routes'
import { IconBasket } from '@components/UI/Icons/VD/General'
import { SlideHoverCta, UtilityNavWrapper } from '../NavigationBar.style'
import MyAccountNavigation from './MyAccountNavigation'

type NavigationBarUtilitiesProps = {
  formattedSubTotal?: string
}

const NavigationUtilities: React.FC<NavigationBarUtilitiesProps> = ({ formattedSubTotal }) => {
  const { t } = useTranslation()

  return (
    <UtilityNavWrapper>
      <MyAccountNavigation />
      <SlideHoverCta
        isBasket
        prefetch={false}
        href={`/${CART}`}
        data-name={'miniBasket'}
        data-tealium-ignore="true"
        aria-label={t('Header.Actions.Cart')}
        onClick={() => {
          if (window?.tealium_data2track) {
            const tealiumPayload = {
              id: 'Click',
              Click_FocusElement: '[ HTMLAnchorElement ]',
              data_element_id: 'MainNav_Bag',
              data_description: '',
              data_analytics_available_call: '1',
            }
            window.tealium_data2track.push(tealiumPayload)
          }
        }}
      >
        <IconBasket />
        <span>{formattedSubTotal}</span>
      </SlideHoverCta>
    </UtilityNavWrapper>
  )
}

export default NavigationUtilities
