import { useTranslation } from 'next-i18next'
import {
  StyledIconFacebook,
  StyledIconInstagram,
  StyledIconLinkedin,
  StyledIconPinterest,
  StyledIconTiktok,
  StyledIconTwitter,
  StyledIconYoutube,
} from './SocialLinks.style'

import { useSite } from '@foundation/hooks/useSite'

type GetSocialLinksProps = {
  size?: number
}

export const GetSocialLinks = (props: GetSocialLinksProps) => {
  const { size = 32 } = props
  const { mySite } = useSite()
  const { t } = useTranslation()

  const checkLocale = (locale: string) => {
    return mySite.locale.toLowerCase() === locale
  }

  let socialLinks = [
    {
      label: 'Facebook',
      url: t('Footer.SocialLinks.facebook'),
      icon: <StyledIconFacebook size={size} />,
    },
    {
      label: 'Instagram',
      url: t('Footer.SocialLinks.instagram'),
      icon: <StyledIconInstagram size={size} />,
    },
    {
      label: 'X',
      url: t('Footer.SocialLinks.twitter'),
      icon: <StyledIconTwitter size={size} />,
    },
    {
      label: 'TikTok',
      url: t('Footer.SocialLinks.tiktok'),
      icon: <StyledIconTiktok size={size} />,
    },
    {
      label: 'YouTube',
      url: t('Footer.SocialLinks.youtube'),
      icon: <StyledIconYoutube size={size} />,
    },
    {
      label: 'Linkedin',
      url: t('Footer.SocialLinks.linkedin'),
      icon: <StyledIconLinkedin size={size} />,
    },
    {
      label: 'Pinterest',
      url: t('Footer.SocialLinks.pinterest'),
      icon: <StyledIconPinterest size={size} />,
    },
  ]

  if (checkLocale('de_de')) {
    socialLinks = socialLinks.filter(link => ['Facebook', 'Instagram', 'YouTube'].includes(link.label))
  } else if (checkLocale('en_uk') || checkLocale('en_ie')) {
    socialLinks = socialLinks.filter(link =>
      ['Facebook', 'Instagram', 'TikTok', 'X', 'YouTube', 'Linkedin', 'Pinterest'].includes(link.label)
    )
  } else if (checkLocale('en_ca')) {
    const order = ['Twitter', 'Facebook', 'TikTok', 'Instagram', 'YouTube', 'Pinterest']
    socialLinks = order.map(label => socialLinks.find(link => link.label === label)!)
  } else {
    socialLinks = socialLinks.filter(link => ['Facebook', 'Instagram', 'X'].includes(link.label))
  }

  return socialLinks
}
