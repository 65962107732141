import { paymentMethodsSelector } from '@redux/selectors/site'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'

import BottomFooter from './components/BottomFooter'
import {
  StyledFooterContainer,
  StyledFooterWrapper,
  StyledFooterRow,
  StyledFooterLogo,
  StyledDesktopWrapper,
} from './Footer.style'
import Container from '@components/UI/Container'
import Logo from '@components/UI/Logo'

import FooterLinks from '@components/UI/FooterLinks'
import { StyledFooterLinkHeading } from '../../components/UI/FooterLinks/FooterLinks.style'
import { formatNavigationLinks } from '@utils/navigation'
import { ICMChannel, ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'

import { LogoutInterceptListener } from '@components/DirectBilling'
import NewsLetter from '@layouts/Footer/components/NewsLetter'
import PaymentMethod from './components/PaymentMethod'
import FooterContact from './components/FooterContact'
import { AppState } from '@redux/store'
import TrustmarkIcons from '@components/UI/TrustmarkIcons'
import { useSite } from '@foundation/hooks/useSite'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
export interface FooterProps {
  seoAltUrl?: string
}

export const contentsFooter = (footerPlacements: IPlacement[]) => {
  const footerNavigation = footerPlacements.find(placement => placement.name === 'footer_navigation')
  const navigation = formatNavigationLinks((footerNavigation?.items as ICMChannel[] | ICMExternalPage[]) || [])
  const newsLetter = footerPlacements.find(placement => placement.name === 'footer_newsletter')
  const newsLetterPopup = footerPlacements.find(placement => placement.name === 'footer_newsletter_overlay')
  const crossSiteLinks = footerPlacements.find(placement => placement.name === 'footer_cross_site_links')
  const copyRight = footerPlacements.find(placement => placement.name === 'footer_copyright')
  const checkoutFooter = footerPlacements.find(placement => placement.name === 'footer_checkout')
  const everythingElseLinks = footerPlacements.find(
    placement => placement.name === 'footer_other_links'
  ) as IPlacement<ICMExternalPage>
  const contactFooter = footerPlacements.find(
    placement => placement.name === 'footer_contact'
  ) as IPlacement<ICMArticle>

  return {
    navigation,
    footerNavigation,
    crossSiteLinks,
    copyRight,
    newsLetter,
    newsLetterPopup,
    checkoutFooter,
    contactFooter,
    everythingElseLinks,
  }
}

const Footer: React.FC<FooterProps> = ({ seoAltUrl }) => {
  const { t } = useTranslation()
  const footerPaymentMethods = useSelector(paymentMethodsSelector)
  const footerData = useSelector((state: AppState) => state.cms.footer)

  const { mySite } = useSite()
  const isDirectBillingEnabled = mySite.xStoreCfg?.DIRECT_BILLING_ENABLED

  if (!footerData) return null

  const { navigation, footerNavigation, newsLetter, newsLetterPopup, contactFooter, everythingElseLinks } =
    contentsFooter(footerData?.footerPlacements)

  const footerPaymentText = t('FooterPaymentMethod.Labels.GuaranteeSecureTransaction')?.toUpperCase()

  return (
    <StyledFooterContainer data-element-id="Footer" className="content-visibility">
      {isDirectBillingEnabled && <LogoutInterceptListener />}
      <StyledFooterWrapper>
        <Container>
          <StyledFooterRow>
            <StyledFooterLogo>
              <Logo />
            </StyledFooterLogo>
            <StyledDesktopWrapper>
              {newsLetter && newsLetterPopup && (
                <NewsLetter newsLetter={newsLetter} newsLetterPopup={newsLetterPopup} />
              )}
            </StyledDesktopWrapper>
          </StyledFooterRow>
          <FooterLinks
            data-cm-metadata={`[{"_":"properties.placement-${footerNavigation?.name}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${footerNavigation?.name}"}]}]`}
            links={navigation}
          >
            {footerPaymentMethods && (
              <PaymentMethod availablePaymentMethods={footerPaymentMethods}>
                <StyledFooterLinkHeading variant="button">{footerPaymentText}</StyledFooterLinkHeading>
              </PaymentMethod>
            )}
            <TrustmarkIcons />
          </FooterLinks>
          {contactFooter && <FooterContact id={contactFooter?.name} items={contactFooter?.items} />}
        </Container>
      </StyledFooterWrapper>
      <BottomFooter addressComponent={contactFooter?.items[1]} everythingElseLinks={everythingElseLinks} />
    </StyledFooterContainer>
  )
}

export default Footer
