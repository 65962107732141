import { ReactNode, useState } from 'react'
import {
  StyledFooterLinksWrapper,
  StyledFooterLinkHeading,
  StyledFooterPlaceholder,
  StyledFooterAccordion,
  StyledFooterAccordionSummary,
  AccordionLinksContent,
  StyledMobileOrTabletWrapper,
  StyledDesktopWrapper,
} from './FooterLinks.style'
import LinkList from '../../UI/LinkList'
import LinkListItem from '../../UI/LinkListItem'
import { NavigationItem } from '../../../utils/navigation'
import { ArrowDownIcon } from '../../UI/Icons/arrows'
import Log from '../../../services/Log'
import { Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'
import { useBreakpoint } from '@hooks/useBreakpoint'

type FooterLinksProps = {
  links: NavigationItem[]
  children?: ReactNode
}

function FooterLinks(props: FooterLinksProps) {
  const { links, children } = props
  const [expandedId, setExpandedId] = useState<string>('')
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const { isDesktop } = useBreakpoint()

  if (!links) {
    Log.info('No links provided to FooterLinks')
    return null
  }

  const formattedLinks = links?.map(link => {
    const { id, children } = link

    const formattedChildren = children?.map((child, index) => {
      return {
        ...child,
        id: `${id}-${index}`,
      }
    })

    return {
      ...link,
      children: formattedChildren,
    }
  })

  const handleOnChange = (id: string) => {
    setExpandedId(expandedId === id ? '' : id)
  }

  const renderLinkList = (links: NavigationItem[] = []) => {
    if (!links) return null
    return (
      <LinkList>
        <ul>
          {links?.map(link => (
            <li key={link?.id}>
              <LinkListItem
                href={link?.url!}
                locale={locale as string}
                isLocaleDomain={isLocaleDomain}
                locationOrigin={locationOrigin}
              >
                <Typography variant="body2">{link?.title}</Typography>
              </LinkListItem>
            </li>
          ))}
        </ul>
      </LinkList>
    )
  }

  return (
    <StyledFooterLinksWrapper>
      {children ? <StyledFooterPlaceholder>{children}</StyledFooterPlaceholder> : null}
      {formattedLinks?.map(item =>
        isDesktop ? (
          <StyledDesktopWrapper key={item?.id}>
            <div key={item?.id}>
              <StyledFooterLinkHeading variant="button">{item?.title}</StyledFooterLinkHeading>
              {renderLinkList(item?.children)}
            </div>
          </StyledDesktopWrapper>
        ) : (
          <StyledMobileOrTabletWrapper key={item?.id}>
            <StyledFooterAccordion
              onChange={() => handleOnChange(item?.id)}
              expanded={expandedId === item?.id}
              key={item?.id}
            >
              <StyledFooterAccordionSummary expandIcon={<ArrowDownIcon />}>
                <StyledFooterLinkHeading variant="button">{item.title}</StyledFooterLinkHeading>
              </StyledFooterAccordionSummary>
              <AccordionLinksContent>{renderLinkList(item.children)}</AccordionLinksContent>
            </StyledFooterAccordion>
          </StyledMobileOrTabletWrapper>
        )
      )}
    </StyledFooterLinksWrapper>
  )
}

export default FooterLinks
