import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IPlacement } from '@typesApp/cmsPlacement/Placement'
//components
import { hamburgerMenuOpenSelector, openDrawerSearchSelector } from '@features/ui/selector'
//queries
import { INVENTORY } from '@constants/common'
import { ACCOUNT, CART, CHECKOUT, HOME, SIGNIN, STORELOCATOR, STORELOCATOR_FR } from '@constants/routes'
import { currentContractIdSelector } from '@features/contract/selector'
import { orderApi } from '@features/order/query'
import { setOpenDrawerSearch } from '@features/ui/action'
import { loginStatusSelector, wcTokenSelector } from '@features/user/selector'
import { fetchWishlistAction } from '@features/wishList/action'
import { wishlistEnabledSelector } from '@features/wishList/selector'
import { useSite } from '@foundation/hooks/useSite'
import useScrollingUp from '@hooks/useScrollingUp/useScrollingUp'
import { useAbandonedOrder } from '@pages_views/Cart/useAbandonedOrder'
import { AppState } from '@redux/store'
import { CartPayload } from '@typesApp/cart'
import Axios, { Canceler } from 'axios'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation'
import {
  HamburgerMenuGreyBackground,
  StyledWrapperHeader,
  StyledKeysellingBarWrapper,
} from '@layouts/Header/Header.style'
import { HeaderItems } from '@layouts/Header/HeaderItems'
import { Seo } from './components/Seo'
import { usePageType } from '@foundation/hooks/usePageType'
import { setMonetatePageType } from '@foundation/monetate/lib'
import { listenMonetateQ } from '@foundation/monetate/useMonetate'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { KEYSELLINGBAR_WRAPPER_CLASSNAME, PROMOBAR_WRAPPER_CLASSNAME } from '@constants/ui'
import { useDynamicCmsContent } from '@foundation/hooks/useDynamicCmsContent'
import { seoAlternateHrefsSelector } from '@features/seo/slice'
import { useGetPageStaticLandingQuery } from '@features/cms/query'

const PromotionBar = dynamic(() => import('@layouts/Header/components/PromotionBar'))
const HamburgerMenu = dynamic(() => import('./components/HamburgerMenu'), {
  ssr: false,
})
const KeySellingBar = dynamic(() => import('../../components/KeySellingBar'))
const SearchHeader = dynamic(() => import('./components/SearchHeader'), {
  ssr: false,
})

const contentsHeader = (headerPlacements: IPlacement[]) => {
  const benefitBar = headerPlacements.find(placement => placement.name === 'header_benefit_bar')
  const trendingNow = headerPlacements.find(placement => placement.name === 'header_search_trending_now')
  const searchBanner = headerPlacements.find(placement => placement.name === 'header_search_banner')
  const promoBar = headerPlacements.find(placement => placement.name === 'header_promo_bar')
  const findStore = headerPlacements.find(placement => placement.name === 'header_find_a_store')
  const keySellingBar = headerPlacements.find(placement => placement.name === 'header_key_selling_bar')

  return {
    benefitBar,
    trendingNow,
    searchBanner,
    promoBar,
    findStore,
    keySellingBar,
  }
}

const Header: FC = () => {
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const header = useSelector((s: AppState) => s.cms.header)
  const headerPlacements = useDynamicCmsContent(header?.headerPlacements ?? []).dynamicContentBanners
  const { benefitBar, promoBar, keySellingBar, findStore } = contentsHeader(headerPlacements)
  const alternateHrefs = useSelector(seoAlternateHrefsSelector)

  const extraCSS = header?.extraCSS || []
  const extraJS = header?.extraJS || []
  const isLoggedIn = useSelector(loginStatusSelector)
  const { isMobile } = useBreakpoint()
  const isHamburgerDrawerOpen = useSelector(hamburgerMenuOpenSelector)
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)
  const wcToken = useSelector(wcTokenSelector)
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const [cancels] = useState<Canceler[]>([])
  const contractId = useSelector(currentContractIdSelector)
  const scrolled = useScrollingUp()
  const router = useRouter()
  const pathname = usePathname()
  const { pageType } = usePageType()
  const isCartLoadRequired = pathname.includes(CART) || pathname.includes(CHECKOUT)
  const isSignInPage = pathname.includes(SIGNIN)
  const [loading, setLoading] = useState<boolean>(true)
  const [shouldRenderHamburgerDrawer, setShouldRenderHamburgerDrawer] = useState<boolean>(false)
  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight)
    }
    setLoading(false)
  }, [isMobile])

  const timestampRef = useRef(Date.now()).current
  useAbandonedOrder()

  const [getCart] = orderApi.endpoints.getCart.useLazyQuery()

  const defaultCurrencyID = useMemo<string>(() => mySite.defaultCurrencyID || '', [mySite])

  const checkInventory = useMemo<boolean>(() => mySite.inventorySystem === INVENTORY.NON_ATP, [mySite.inventorySystem])

  const isInSignInPageAfterLogout = () => isSignInPage && !wcToken

  const payloadBase: CartPayload = {
    storeId: mySite.storeID,
    currency: defaultCurrencyID,
    contractId,
    checkInventory,
    cancelToken: new Axios.CancelToken(c => cancels.push(c)),
  }

  const pageInfo = useGetPageStaticLandingQuery(
    { pageId: pathname.split('/c/')?.[1] },
    {
      skip: !pathname?.split('/c/')?.[1],
    }
  )

  const changeLanguage = useCallback(
    (language: string, country: string) => {
      const targetLocale = `${language}-${country.toLowerCase()}`
      const isCartPage = location.pathname.endsWith(`/${CART}`) /* look for alternate links on SEO endpoint */
      const isStoreLocatorPage = pathname.includes(STORELOCATOR) || pathname.includes(STORELOCATOR_FR)

      let targetUrl =
        alternateHrefs &&
        alternateHrefs.find(x => x.key === targetLocale)?.value /* look for alternate links on CMS static page */

      const { data: staticPageData } = pageInfo
      if (staticPageData?.alternateLinks) {
        targetUrl = staticPageData.alternateLinks.find(x => x.includes(targetLocale))
      }

      if (pathname.includes(ACCOUNT)) {
        targetUrl = pathname
      } else if (isStoreLocatorPage) {
        const storeLocatorPath = pathname.includes(STORELOCATOR_FR) ? STORELOCATOR : STORELOCATOR_FR
        targetUrl = storeLocatorPath
      } /* try basic locale replace if all else fails */

      if (!targetUrl || isCartPage) {
        targetUrl = pathname
      }

      router
        .push(targetUrl, undefined, {
          locale: targetLocale,
        })
        .then(() => {
          router.reload()
        })
    },
    [alternateHrefs, pageInfo, pathname, router]
  )

  useEffect(() => {
    if (mySite && isWishlistEnabled && !!wcToken) {
      dispatch(fetchWishlistAction(mySite.storeID))
    }
  }, [isWishlistEnabled, mySite, wcToken, dispatch])

  //refetch cart from header if the page is not cart or checkout, cart update will be managed in the individual pages
  useEffect(() => {
    if (!isCartLoadRequired && mySite && wcToken) {
      getCart({
        ...payloadBase,
        fetchCatentries: true,
        fetchShippingInfo: false,
        refetch: false,
        sessionId: timestampRef,
        updateProducts: true,
      })
    }
  }, [isCartLoadRequired, isLoggedIn])

  //refetch cart in the sign in page after logout to restart the basket price calculation
  //not merged to the above useEffect to keep a clear separation of concerns
  useEffect(() => {
    if (isInSignInPageAfterLogout()) {
      getCart({
        ...payloadBase,
        fetchCatentries: true,
        fetchShippingInfo: false,
        refetch: false,
        sessionId: timestampRef,
        updateProducts: true,
      })
    }
  }, [isSignInPage])

  const [isHeaderVisible, setHeaderVisibility] = useState<boolean>(true)
  const monetateLoaded = listenMonetateQ()

  useEffect(() => {
    const shouldBeVisible = scrolled === null || isSearchDrawerOpen
    setHeaderVisibility(shouldBeVisible)
  }, [scrolled, isSearchDrawerOpen])

  useEffect(() => {
    dispatch(setOpenDrawerSearch(false))
  }, [router.asPath])

  useEffect(() => {
    if (pageType && pageType.length > 0 && monetateLoaded) {
      setMonetatePageType(pageType)
    }
  }, [pageType, monetateLoaded])

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null

    if (isHamburgerDrawerOpen) {
      timer = setTimeout(() => {
        setShouldRenderHamburgerDrawer(true)
      }, 0)
    } else {
      setShouldRenderHamburgerDrawer(false)
    }

    return () => {
      if (timer !== null) {
        clearTimeout(timer)
      }
    }
  }, [isHamburgerDrawerOpen])

  return (
    <>
      <Seo extraJS={extraJS} extraCSS={extraCSS} />
      {shouldRenderHamburgerDrawer && <HamburgerMenuGreyBackground />}
      <StyledWrapperHeader
        isSticky={!isMobile}
        loading={loading}
        className={clsx({ 'is-scrolled': !isHeaderVisible })}
        ref={ref}
      >
        <HeaderItems
          benefitBar={benefitBar}
          changeLanguage={changeLanguage}
          findStore={findStore}
          hasScrolled={scrolled!}
          isHeaderVisible={isHeaderVisible}
        />
        <HamburgerMenu open={shouldRenderHamburgerDrawer} />

        {promoBar && (
          <div className={PROMOBAR_WRAPPER_CLASSNAME}>
            <PromotionBar data={promoBar} />
          </div>
        )}
        <SearchHeader isHeaderVisible={!!scrolled} />
      </StyledWrapperHeader>
      {keySellingBar && (
        <StyledKeysellingBarWrapper marginTop={height} className={KEYSELLINGBAR_WRAPPER_CLASSNAME}>
          {<KeySellingBar items={keySellingBar.items} />}
        </StyledKeysellingBarWrapper>
      )}
    </>
  )
}

export default Header
