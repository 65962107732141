import { useSite } from '@foundation/hooks/useSite'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import config from '@configs/index'
import { LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION } from '@redux/actions/subscription'
import {
  SUBSCRIPTION_FREQUENCY_UNIT,
  SUBSCRIPTION_TYPE,
  SubscriptionModuleValue,
  SubscriptionPeriodOption,
} from '@typesApp/productSubscription'
import { useCart } from '@hooks/useCart'
import { getSubscriptionFrequencyValue, hasActiveSubscriptionInCart } from '@utils/order'
import { setOrderType } from '@redux/slices/checkout'

const useLoadProductSubscriptionConfig = () => {
  const { mySite } = useSite()
  const dispatch = useDispatch()
  const { fetchCart, cart, isCartGetSuccess, cartLoading } = useCart()

  const {
    enabledLocales,
    isAggregateProductsByRecFrequency,
    allowedProducts,
    frequency,
    frequencyUnitCfg,
    showOnCartRecap,
    showOnOnePageCheckout,
    showOnPdp,
  } = config.productSubscription
  const [subscriptionFrequencyOptions, setSubscriptionFrequencyOptions] = useState<SubscriptionPeriodOption[]>([])
  const [initialOrderTypeValue, setInitialOrderTypeValue] = useState<SubscriptionModuleValue | null>(null)

  const currentLocale = mySite?.locale.toLowerCase().replace('_', '-')
  const isSubscriptionEnabled =
    mySite?.xStoreCfg?.SUBSCRIPTION_ENABLED ||
    !!(enabledLocales?.split(',') ?? []).map(l => l.toLowerCase()).indexOf(currentLocale)
  const isSubscriptionAggregateProductsByRecFrequency =
    mySite?.xStoreCfg?.SUBSCRIPTION_AGGREGATE_PRODUCTS_BY_REC_FREQUENCY || isAggregateProductsByRecFrequency
  const isSubscriptionFeatureEnabled = isSubscriptionEnabled && isSubscriptionAggregateProductsByRecFrequency

  const subscriptionFrequency = mySite?.xStoreCfg?.SUBSCRIPTION_FREQUENCY_ALLOWABLE_AMOUNT ?? frequency
  const subscriptionFrequencyUnitCfg = mySite?.xStoreCfg?.SUBSCRIPTION_FREQUENCY_ALLOWABLE_UOM ?? frequencyUnitCfg
  const subscriptionFrequencyDefault =
    mySite?.xStoreCfg?.SUBSCRIPTION_FREQUENCY_DEFAULT_ALLOWABLE_AMOUNT ?? frequencyUnitCfg

  const subscriptionFrequencyUnit = subscriptionFrequencyUnitCfg
    ? SUBSCRIPTION_FREQUENCY_UNIT[subscriptionFrequencyUnitCfg]
    : ''

  useEffect(() => {
    if (subscriptionFrequency) {
      const options = subscriptionFrequency
        ?.split(',')
        ?.map((frequency: string) => ({ key: frequency, value: parseInt(frequency) }))
      setSubscriptionFrequencyOptions(options)
    }
  }, [subscriptionFrequency])

  const getSubscriptionPeriod = (
    subscriptionFrequencyValue: string | undefined,
    subscriptionFrequencyDefault: string | undefined
  ): number => {
    const period = subscriptionFrequencyValue
      ? Number(subscriptionFrequencyValue.split('|')[0])
      : Number(subscriptionFrequencyDefault)

    return period
  }

  useEffect(() => {
    if (!initialOrderTypeValue && cart) {
      const isSubscriptionActive = hasActiveSubscriptionInCart(cart)
      const subscriptionFrequencyValue = getSubscriptionFrequencyValue(cart)
      const subscriptionPayload = {
        type: isSubscriptionActive ? SUBSCRIPTION_TYPE.SUBSCRIPTION : SUBSCRIPTION_TYPE.ONE_TIME_PURCHASE,
        period: getSubscriptionPeriod(subscriptionFrequencyValue, subscriptionFrequencyDefault),
      }

      setInitialOrderTypeValue(subscriptionPayload)
      dispatch(setOrderType(subscriptionPayload))
    }
  }, [isCartGetSuccess, cart])

  useEffect(() => {
    dispatch(
      LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION({
        enabled: isSubscriptionFeatureEnabled,
        allowedProducts,
        showOnCartRecap,
        showOnPdp,
        showOnOnePageCheckout,
        frequencyUnit: subscriptionFrequencyUnit,
        frequencyOptions: subscriptionFrequencyOptions,
        frequencyDefault: subscriptionFrequencyDefault,
        initialOrderTypeValue,
      })
    )
  }, [isSubscriptionEnabled, allowedProducts, subscriptionFrequencyOptions, initialOrderTypeValue])
}

export default useLoadProductSubscriptionConfig
