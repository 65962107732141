import React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import Logo from '@components/UI/Logo'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import useBreakpoints from '@hooks/useBreakpoints'
import { locationOriginSelector } from '@redux/selectors/site'
import { replaceLocaleInUrl } from '@utils/locale'
import styles from '@layouts/Header/components/NavigationBar/styles/NavigationMenuLogo.module.scss'

type NavigationBarLogoProps = {
  scrolledUp?: boolean
}

const NavigationMenuLogo: React.FC<NavigationBarLogoProps> = props => {
  const router = useRouter()
  const { isDesktop } = useBreakpoints()
  const { langCode } = useStoreIdentity()
  const locationOrigin = useSelector(locationOriginSelector)

  const homePageURL = replaceLocaleInUrl({
    isLocaleDomain: router.isLocaleDomain,
    locationOrigin,
    locale: router.locale,
    href: `/${langCode}`,
  }) as string

  return (
    <a
      className={clsx(styles.onScrollLogoLink, {
        [styles.isSticky]: props.scrolledUp,
      })}
      href={homePageURL}
    >
      <Logo
        variant="white"
        className={clsx(isDesktop && props.scrolledUp && styles.logoContainer)}
        showSymbolOnly={isDesktop && props.scrolledUp}
      />
    </a>
  )
}

export default NavigationMenuLogo
