import React from 'react'

import { ChatButtonContainer, ChatButtonLabel } from './ChatButton.style'
import { useTranslation } from 'next-i18next'
import { matchPath } from 'react-router-dom'
import { CART, CHECKOUT_PAYMENT, CHECKOUT_REVIEW, CHECKOUT_SHIPPING, ORDER_CONFIRMATION } from '../../constants/routes'

import useScrollingUp from '../../hooks/useScrollingUp/useScrollingUp'
import { usePathname } from 'next/navigation'

import { ChatIcon } from '@storybook/icons'
import { useGenesys } from '../../foundation/genesys/useGenesys'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

const ALLOWED_STORES = ['70201', '70202', '70203']
const DISALLOW_LIST = [CART, CHECKOUT_SHIPPING, CHECKOUT_PAYMENT, CHECKOUT_REVIEW, ORDER_CONFIRMATION]

export const ChatButton = () => {
  const { mySite } = useSite()
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const hasScrolled = useScrollingUp()
  const { isLoading, toggleGenesys } = useGenesys()
  const pathname = usePathname()

  const isVisible =
    ALLOWED_STORES.find(id => id === mySite.storeID) &&
    !isLoading &&
    !DISALLOW_LIST.some(path => matchPath(`/${langCode}/${path}`, pathname)) &&
    hasScrolled !== null

  if (isVisible) {
    return (
      <ChatButtonContainer onClick={toggleGenesys}>
        <ChatIcon color={'#ffffff'} />
        <ChatButtonLabel>{t('Chat.Cta')}</ChatButtonLabel>
      </ChatButtonContainer>
    )
  }

  return null
}
