import React from 'react'
import {
  ContentCtaListOfBrandIcon,
  ContentListOfBrandIcon,
  TitleListOfBrandIcon,
  WrapperListOfBrandIcon,
} from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/ListOfBrandIcons/ListOfBrandIcon.style'
import CmsCtaMegaMenu from '@components/Cms/CmsComponents/CmsCtaMegaMenu'
import useBreakpoints from '@hooks/useBreakpoints'
import BrandIconLink from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/ListOfBrandIcons/components/BrandIconLink'
import { MegaMenuColumnComponentProps } from '@layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent'
import ScrollableContainer from '../../../../UI/ScrollableContainer'
import { MenuColumnCtaContainer } from '@layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent.style'
import useTheme from '@mui/material/styles/useTheme'
import { isCMCollection, isCMExternalChannel, isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import { StyledAnchor } from '@components/UI/Anchor'
import HTMLReactParser from 'html-react-parser'

const ListOfBrandIcon: React.FC<MegaMenuColumnComponentProps> = ({ enableScroll, placement, scrollGradientColor }) => {
  const theme = useTheme()
  const { isDesktop, isTabletLandscape } = useBreakpoints()

  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const collectionItem = placement?.items?.find(isCMCollection)
  const teaserItem = placement?.items?.find(isLXTeaser)

  if (!collectionItem) return null
  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings || []
  const teasableItems = collectionItem.teasableItems?.filter(isCMExternalChannel) || []
  const title = collectionItem.collectionTitle || ''
  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <WrapperListOfBrandIcon>
      <TitleListOfBrandIcon>{title}</TitleListOfBrandIcon>
      {isDesktop && (
        <ContentCtaListOfBrandIcon>
          {teaserLXCallToActionSettings?.map((actionSettings, index) => {
            const link = actionSettings?.target?.formattedUrl || ''

            return (
              <>
                <StyledAnchor href={link} sx={{ fontSize: '1rem' }}>
                  {HTMLReactParser(actionSettings?.callToActionText || '')}
                </StyledAnchor>
              </>
            )
          })}
        </ContentCtaListOfBrandIcon>
      )}
      <ScrollableContainer
        containerCSSProps={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(6),
        }}
        gradientColor={scrollGradientColor}
        isEnabled={enableScroll}
      >
        <WrapperListOfBrandIcon {...metaData} isTabletLandscape={isTabletLandscape}>
          <ContentListOfBrandIcon isTabletLandscape={isTabletLandscape}>
            {teasableItems.map(teasableItem => (
              <React.Fragment key={teasableItem.id}>
                <BrandIconLink
                  teasableItem={teasableItem}
                  width={isTabletLandscape ? '90%' : '100%'}
                  isTabletLandscape={isTabletLandscape}
                />
              </React.Fragment>
            ))}
          </ContentListOfBrandIcon>
        </WrapperListOfBrandIcon>
      </ScrollableContainer>
      {isDesktop && (
        <MenuColumnCtaContainer>
          {/* {teaserLXCallToActionSettings?.map((actionSettings, index) => (
            <CmsCtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              dataElementId={`X_X_1Placement_Banner${index}_CTA`}
              actionSettings={actionSettings}
            />
          ))} */}
        </MenuColumnCtaContainer>
      )}
    </WrapperListOfBrandIcon>
  )
}

export default ListOfBrandIcon
