import React, { useEffect, useMemo } from 'react'
import { StyledMainNav } from './NavigationBar.style'
import NavigationMenuDesktop from './components/NavigationMenuDesktop'
import Container from '@components/UI/Container'
import { useTranslation } from 'next-i18next'
import CurrencyService from '@services/CurrencyService'
import { useSelector } from 'react-redux'
import { cartSelector, orderItemsSelector, isCartThresholdReachedSelector } from '@features/order/selector'
import { useSite } from '@foundation/hooks/useSite'
import { IOrderItem } from '@typesApp/order'
import { getParsedOrderItems, calculateGiftProductsDiscounts } from '@utils/order'
import { CartThresholdReachedModal } from '@components/CartThresholdReachedModal'
import NavigationMenuLogo from '@layouts/Header/components/NavigationBar/components/NavigationMenuLogo'
import NavigationUtilities from '@layouts/Header/components/NavigationBar/components/NavigationUtilities'
import styles from '@layouts/Header/components/NavigationBar/styles/NavigationBar.module.scss'

type NavigationBarProps = {
  isSticky?: boolean
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isSticky = false }) => {
  const { mySite } = useSite()
  const { t } = useTranslation()
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)

  // Calculate subtatal considering the discount
  const parsedOrderItems = useMemo<IOrderItem[] | null>(
    () => (orderItems ? getParsedOrderItems(orderItems) : null),
    [orderItems]
  )
  const totalProductPrice = cart?.totalProductPrice ? parseFloat(cart?.totalProductPrice) : 0
  const parsedOrderItemsList = useMemo(() => parsedOrderItems || [], [parsedOrderItems])
  const giftProductsDiscounts = calculateGiftProductsDiscounts(parsedOrderItemsList)
  const partialSubtotal =
    giftProductsDiscounts > 0 && totalProductPrice > 0 ? totalProductPrice - giftProductsDiscounts : totalProductPrice
  const formattedSubTotal = CurrencyService.formatValueWithCurrency({
    currency: cart?.grandTotalCurrency,
    value: partialSubtotal,
    locale: mySite?.locale,
  })

  const isCartThresholdReached = useSelector(isCartThresholdReachedSelector)

  useEffect(() => {
    const handleMouseDown = event => {
      let target = event.target

      while (target && target !== document) {
        if (target.getAttribute('data-tealium-ignore') === 'true') {
          event.stopPropagation()
          return
        }
        target = target.parentElement
      }
    }

    document.addEventListener('mousedown', handleMouseDown, true)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown, true)
    }
  }, [])

  return (
    <>
      {isCartThresholdReached && (
        <CartThresholdReachedModal
          grandTotal={cart?.grandTotal}
          currency={cart?.grandTotalCurrency}
          isOpen={isCartThresholdReached}
        />
      )}
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.innerWrapper}>
            <NavigationMenuLogo scrolledUp={!!isSticky} />
            <StyledMainNav>
              <NavigationMenuDesktop />
              <NavigationUtilities formattedSubTotal={formattedSubTotal} />
            </StyledMainNav>
          </div>
        </Container>
      </div>
    </>
  )
}

export default NavigationBar
