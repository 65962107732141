import { useTranslation } from 'next-i18next'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useEffect, useState } from 'react'
import { useSite } from '@foundation/hooks/useSite'
export const useCountriesLinks = () => {
  const { t } = useTranslation()
  const [currentUrl, setCurrentUrl] = useState('')
  const { langCode } = useStoreIdentity()
  const { mySite } = useSite()
  const isFRorBEFR = ['fr-fr', 'fr-be'].includes(langCode.toLowerCase())
  const defaultPrependURL = 'https://www.visiondirect'
  const storeName = mySite?.xStoreCfg?.brand?.toLowerCase() ?? 'visiondirect'

  useEffect(() => {
    setCurrentUrl(window.location.origin)
  }, [])

  const getUrlPrepend = (currentUrl, countryCode) => {
    return currentUrl ? currentUrl?.substring(0, currentUrl.indexOf(storeName)) + storeName : defaultPrependURL
  }

  const createCountryLink = countryCode => {
    const domain = (countryCode === 'uk' && 'co.uk') || countryCode
    const isBEFRPath = countryCode === 'be' && langCode === 'fr-be'
    const urlPrepend = getUrlPrepend(currentUrl ?? '', countryCode)
    return {
      label: `${t(`Footer.Labels.CountryList.${countryCode}.label`)}`,
      url: `${urlPrepend}.${domain}${isBEFRPath ? '/fr/' : ''}`,
      isActive: `${t(`Footer.Labels.CountryList.${countryCode}.active`)}`,
    }
  }

  const countries = ['be', ...(isFRorBEFR ? ['es'] : []), 'fr', 'ie', 'it', 'nl', ...(!isFRorBEFR ? ['es'] : []), 'uk']

  return langCode === 'de-de' ? [] : countries.map(countryCode => createCountryLink(countryCode))
}
