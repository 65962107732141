import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV3 } from '@constants/ui'
import { Typography } from '@mui/material'
import { IconCross } from '@components/UI/Icons/VD/General'

const IconWrapperBigMenuBanner = styled('div', {
  name: 'IconWrapperBigMenuBanner',
  slot: 'Image',
})(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const ImageBigMenuBanner = styled('div', {
  name: 'ImageBigMenuBanner',
  slot: 'Image',
})(({ theme }) => ({
  position: 'relative',

  '.lazy-load-wrapper': {
    display: 'inline-block',
  },

  [theme.breakpoints.up('md')]: {
    margin: 0,
    padding: 0,
  },
}))

const StyledContentBigMenuBanner = styled('div', {
  name: 'ContentBigMenuBanner',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(8),
  gap: theme.spacing(4),
  backgroundColor: theme.palette.custom.white,
  textAlign: 'left',
  color: theme.palette.text.dark.primary,
  margin: theme.spacing('auto', 0, 0),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(16),
    position: 'sticky',
    width: '100%',
    bottom: 0,
  },
  p: {
    margin: 0,
  },
}))

const StyledTitleMenuBanner = styled('h3', {
  name: 'TitleMenuBanner',
})(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.neutral.main,
}))

export const TextMenuBanner = styled('div', {
  name: 'BigMenuBanner',
  slot: 'Text',
})({})

const StyledCtaMenuBanner = styled('div', {
  name: 'BigMenuBanner',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    marginTop: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: theme.spacing(100),
    marginBottom: theme.spacing(6),
  },
}))

const StyledCloseIcon = styled(IconCross, {
  name: 'CloseIcon',
})(({ theme }) => ({
  fontSize: 32,
  right: 0,
  top: 0,
  position: 'absolute',
  zIndex: Z_INDEX_LV3,
  backgroundColor: theme.palette.custom.white,
  padding: theme.spacing(6),
  cursor: 'pointer',
  height: 52,
  width: 52,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const StyledButtonWrapper = styled('div', {
  name: 'CtaMegaMenu',
  slot: 'StyledButtonWrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
}))

export {
  ImageBigMenuBanner,
  StyledTitleMenuBanner,
  StyledContentBigMenuBanner,
  StyledCtaMenuBanner,
  IconWrapperBigMenuBanner,
  StyledCloseIcon,
  StyledButtonWrapper,
}
