import { LOCALES_WITH_LANGUAGE_IN_URL } from '@constants/common'
import { useSite } from '@foundation/hooks/useSite'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useRouterChange = () => {
  const router = useRouter()
  const { mySite } = useSite()

  const [isRouterChange, setRouterChange] = useState(false)

  const isLocaleWithLanguageInURL = LOCALES_WITH_LANGUAGE_IN_URL.includes(mySite?.locale)

  const getPathnameWithLocale = (url: string, shouldAddLocale: boolean): string => {
    const basePath = shouldAddLocale ? `/${router.locale}` : ''
    const sanitizedUrl = url.split('?')[0].toLowerCase()

    return `${basePath}${sanitizedUrl}`
  }

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const currentPath = getPathnameWithLocale(router.asPath, true)
      const nextPath = getPathnameWithLocale(url, !isLocaleWithLanguageInURL)

      if (currentPath !== nextPath) {
        setRouterChange(true)
      }
    }

    const handleRouteChangeComplete = () => {
      setRouterChange(false)
    }

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router])

  return { isRouterChange }
}
