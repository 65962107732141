import React from 'react'
import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { useDispatch } from 'react-redux'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '@features/ui/action'
import { Button } from '@components/UI/Button'
import { useBreakpoint } from '@hooks/useBreakpoint'

interface IProps {
  actionSettings: ITeaserCallToAction
  dataElementId?: string
  isFullWidth?: boolean
  dataName?: string
}

const CmsCtaMegaMenu: React.FC<IProps> = ({ actionSettings, dataElementId, isFullWidth = true, dataName }) => {
  const dispatch = useDispatch()
  const { basePath } = useStoreIdentity()
  const { isDesktop } = useBreakpoint()

  const ctaEnabled = actionSettings?.callToActionEnabled || false
  const text = actionSettings?.callToActionText || ''
  const formattedUrl = actionSettings?.target?.formattedUrl || ''

  const link = `${basePath}${formattedUrl ?? ''}`
  const targetType = actionSettings?.target?.type
  const externalLink = actionSettings?.target?.url || ''

  if (!ctaEnabled) return null

  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))
  }

  return (
    <div onClick={closeMegaMenu}>
      <Button
        data-element-id={dataElementId}
        data-name={dataName}
        href={targetType === 'CMExternalLink' ? externalLink : link}
        fillType="contained"
        target={targetType === 'CMExternalLink' ? '_blank' : '_self'}
        fullWidth={isDesktop ? false : isFullWidth}
      >
        {text}
      </Button>
    </div>
  )
}

export default CmsCtaMegaMenu
