import React from 'react'
import TopBar from '@layouts/Header/components/TopBar'
import NavigationBar from '@layouts/Header/components/NavigationBar/NavigationBar'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'

type HeaderItemsProps = {
  benefitBar?: IPlacement
  changeLanguage?: (lang: string, country: string) => void
  findStore?: IPlacement<IPlacementItem>
  isHeaderVisible: boolean
  hasScrolled: boolean
}

export const HeaderItems: React.FC<HeaderItemsProps> = ({ isHeaderVisible, hasScrolled }) => {
  return (
    <div className="navbar-animation-wrapper">
      <TopBar hasScrolled={hasScrolled} />
      <NavigationBar isSticky={!isHeaderVisible} />
    </div>
  )
}
