import { Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { Accordion, AccordionSummary } from '../../UI/Accordion/legacy'

export const StyledFooterLinksWrapper = styled('div', {
  name: 'Footer',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  margin: theme.spacing(0),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: theme.spacing(16),
    paddingTop: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(16, 0),
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: theme.spacing(16),
  },
}))

export const StyledFooterPlaceholder = styled('div', {
  name: 'Footer',
  slot: 'Placeholder',
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(8, 0),

  [theme.breakpoints.up('md')]: {
    borderBottom: 'none',
    padding: 0,
  },
}))

export const StyledFooterLinkHeading = styled(Typography, {
  name: 'Footer',
  slot: 'Heading',
})(({ theme }) => ({
  display: 'inline-block',
  textTransform: 'uppercase',

  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(6),
  },
}))

export const StyledFooterAccordion = styled(Accordion, {
  name: 'Footer',
  slot: 'Accordion',
})(({ theme }) => ({
  borderBottomColor: theme.palette.divider,
}))

export const StyledFooterAccordionSummary = styled(AccordionSummary, {
  name: 'Footer',
  slot: 'AccordionSummary',
})(({ theme }) => ({
  borderBottomColor: theme.palette.divider,
  padding: theme.spacing(3, 0),
}))

export const AccordionLinksContent = styled('div', {
  name: 'Footer',
  slot: 'AccordionLinksContent',
})(({ theme }) => ({
  marginTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const StyledFooterLink = styled('li', {
  name: 'Footer',
  slot: 'ListItem',
})(({ theme }) => ({
  ...theme.typography.body2,
}))

export const StyledDesktopWrapper = styled('div', {
  name: 'FooterDesktopWrapper',
})(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const StyledMobileOrTabletWrapper = styled(StyledFooterAccordion, {
  name: 'TabletOrMobileWrapper',
})(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))
