import styled from '@mui/material/styles/styled'

export const WrapperListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'isTabletLandscape',
})<{
  isTabletLandscape?: boolean
}>(({ theme, isTabletLandscape }) => ({
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  gap: theme.spacing(6),
  flexDirection: 'column',
  padding: `${theme.spacing(6)} ${theme.spacing(10.5)}`,

  [theme.breakpoints.up('sm')]: {
    padding: `0 ${theme.spacing(12)} ${theme.spacing(6)} ${theme.spacing(6)}`,
  },
}))

export const ContentCtaListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Cta',
})({})

export const TitleListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'bold',
}))

export const ContentListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Content',
  shouldForwardProp: prop => prop !== 'isTabletLandscape',
})<{
  isTabletLandscape?: boolean
}>(({ theme, isTabletLandscape }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(12),
  columnGap: theme.spacing(isTabletLandscape ? 12 : 4),

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '50% 50%',
  },

  [theme.breakpoints.up('md')]: {
    justifyItems: isTabletLandscape ? 'end' : 'center',
    gridTemplateColumns: isTabletLandscape ? '40% 40%' : '50% 50%',
    marginLeft: theme.spacing(isTabletLandscape ? 4 : 0),
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '45% 45%',
  },
}))
