import { googleApiEnabledeSelector, googleApiKeySelector } from '../redux/selectors/site'
import { useEffect, useState } from 'react'

import { ACCOUNT_CHILDREN } from '../constants/routes'
import { StatesProvinces } from '../types/common'
import { countriesListSelector } from '../redux/selectors/account'
import { executeOnce } from '../utils/common'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../foundation/hooks/useStoreIdentity'
import { ADDRESS_BILLING, ADDRESS_SHIPPING, ADDRESS_SHIPPING_BILLING } from '@constants/common'

export const useGoogleSuggestions = (props: { addressType: string; country: string; page: string; state: string }) => {
  const storeCountry = useStoreIdentity().country

  const countryDefaultValue = props.country || storeCountry

  const [gmapLoaded, setGmapLoaded] = useState<boolean>(false)
  const [gmapCountry, setGmapCountry] = useState<string>(storeCountry)
  const [state, setState] = useState<string>('')
  const googleApiEnabled = useSelector(googleApiEnabledeSelector)
  const googleApiKey = useSelector(googleApiKeySelector)
  const [googleAPISuggestionEnabled, setGoogleAPISuggestionEnabled] = useState<boolean>(false)
  const countriesList = useSelector(countriesListSelector)
  const [provinces, setProvinces] = useState<StatesProvinces[]>([])

  const onGoogleScriptLoaded = () => {
    setGmapLoaded(true)
  }

  useEffect(() => {
    if (!googleApiEnabled) {
      return
    }

    if (props.page === ACCOUNT_CHILDREN.ADDRESS_BOOK) {
      if (googleApiEnabled.isEnabledOnAddressBook) {
        setGoogleAPISuggestionEnabled(true)
      }
    } else {
      setGoogleAPISuggestionEnabled(true)
    }

    if (props.page === ACCOUNT_CHILDREN.STORES || props.page === ACCOUNT_CHILDREN.PAYMENT_METHODS) {
      setGoogleAPISuggestionEnabled(true)
    }
  }, [googleApiEnabled])

  useEffect(() => {
    if (googleAPISuggestionEnabled) {
      if (!window.google) {
        return executeOnce(() => {
          const s = document.createElement('script')
          s.type = 'text/javascript'
          s.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`
          s.onload = onGoogleScriptLoaded
          const x = document.getElementsByTagName('script')[0]
          x && x.parentNode && x.parentNode.insertBefore(s, x)
        }, 'dw-gtag-gmap')()
      } else {
        onGoogleScriptLoaded()
      }
    }
  }, [googleAPISuggestionEnabled, window.google])

  useEffect(() => {
    const addressFormDataCountry = props?.country || countryDefaultValue
    const gmapCountry = addressFormDataCountry || storeCountry

    setGmapCountry(gmapCountry)

    if (countriesList?.length) {
      const statesProvinces = countriesList?.find(countryEl => countryEl.code === addressFormDataCountry)?.states

      const firstProvinceOption = statesProvinces && statesProvinces?.length > 0 ? statesProvinces[0] : null
      const stateProvinceDefaultValue = firstProvinceOption?.code || ''

      setState(stateProvinceDefaultValue)

      if (statesProvinces) {
        setProvinces(statesProvinces)
      }
    }
  }, [countriesList, props.country])

  return {
    gmapCountry,
    gmapLoaded,
    googleAPISuggestionEnabled,
    state,
    provinces,
  }
}
