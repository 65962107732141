import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenDrawerNewsLetter } from '@features/ui/action'
import Drawer from '@mui/material/Drawer'
import { openDrawerNewsLetterSelector } from '@features/ui/selector'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { useTranslation } from 'next-i18next'
import { Button } from '@components/UI/Button'
import NewsletterDrawerContent from './NewsLetterDrawerContent'
import { RightItemContent, StyledNewsletterWrapper } from './NewsLetter.style'

interface IProps {
  newsLetter: IPlacement
  newsLetterPopup: IPlacement
}

const NewsLetter: React.FC<IProps> = ({ newsLetter, newsLetterPopup }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const newsletterDrawerOpen = useSelector(openDrawerNewsLetterSelector)

  if (!newsLetter) return null
  const newsLetterName = newsLetter.name ?? ''

  const toggleNewsletterDrawer = () => {
    dispatch(setOpenDrawerNewsLetter(true))
  }

  const handleEscapeKey = event => {
    if (event.key === 'Escape') dispatch(setOpenDrawerNewsLetter(false))
  }

  return (
    <StyledNewsletterWrapper
      data-cm-metadata={`[{"_":"properties.placement-${newsLetterName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${newsLetterName}"}]}]`}
    >
      <div>
        <RightItemContent>
          <Button
            fillType={'fill'}
            variant={'primary'}
            labelText={t('NewsLetterDrawerContent.SignUpCTA')}
            data-element-id={'Footer_NewsletterSignup'}
            data-name="subscribeToNewsLetter"
            onClick={toggleNewsletterDrawer}
          >
            {t('NewsLetterDrawerContent.SignUpCTA')}
          </Button>
        </RightItemContent>
        <Drawer
          anchor={'right'}
          open={newsletterDrawerOpen}
          className="newsletter-menu"
          onClose={() => dispatch(setOpenDrawerNewsLetter(false))}
          onKeyDown={event => handleEscapeKey(event)}
        >
          <NewsletterDrawerContent newsLetterPopup={newsLetterPopup} />
        </Drawer>
      </div>
    </StyledNewsletterWrapper>
  )
}

export default NewsLetter
