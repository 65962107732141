import styles from '@components/SocialMediaItems/SocialLinks.module.scss'
import { GetSocialLinks } from '@layouts/Footer/components/BottomFooter/components/SocialLinks'

export const SocialLinks: React.FC<{
  iconsSize?: number
  iconsColor?: 'white' | 'black'
}> = props => {
  const { iconsSize } = props
  const socialLinks = GetSocialLinks({ size: iconsSize })

  return (
    <ul className={`${styles['social-list']} social-links-list`}>
      {socialLinks.map(social => (
        <li key={social?.label} className={styles['list-item']}>
          <a href={social?.url} target="_blank" rel="noreferrer" title={social?.label}>
            {social?.icon}
          </a>
        </li>
      ))}
    </ul>
  )
}
